import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './App.css';

const searchUrls = [
  "https://hook.us1.make.com/dnqlpfxag5rzl4daad5bxk1red28mhd3",
  "https://hook.us2.make.com/9046agmbe07qp1nx5z350s1bjfykegik",
];

const downloadUrls = [
  "https://hook.us1.make.com/23599xcub5i67pdb2jlnul9nxonhr1yb",
  "https://hook.us2.make.com/gkq0abl5nbgntgfugg2w8wfmwso1jf83"
];

const getSearchUrl = (type, number, useAlternative) => {
  const url = `${useAlternative ? searchUrls[1] : searchUrls[0]}?type_id=${type}&number=${number}`;
  return url;
};

const getDownloadUrl = (name, callback) => {
  let url = `${downloadUrls[0]}?name=${name}`;

  axios.get(url)
    .then(response => {
      if (response.data === "Accepted") {
        url = `${downloadUrls[1]}?name=${name}`;
      }
      callback(url);
    })
    .catch(error => {
      console.error("Error checking download URL:", error);
      callback(url);
    });
};

const App = () => {
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [captchaValid, setCaptchaValid] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!documentType || !documentNumber) {
      setError('Por favor, completa todos los campos.');
      return;
    }

    if (!captchaValid) {
      alert('Por favor, completa el reCAPTCHA.');
      return;
    }

    setLoading(true);
    axios.get(getSearchUrl(documentType, documentNumber, false))
      .then(response => {
        if (response.data === "Accepted") {
          return axios.get(getSearchUrl(documentType, documentNumber, true));
        }
        return response;
      })
      .then(response => {
        if (response.data.length === 0 || response.data.some(cert => cert.name === null)) {
          setCertificates([]);
          setError(
            <>
              No se encontraron certificados para el documento {documentNumber}, para más información clic <strong><a href="https://drive.google.com/file/d/1teuUr91ADMHbnGmliinyuV-pD6va-RtJ/view?usp=sharing" target="_blank" style={{ color: 'red', textDecoration: 'none' }}>aquí</a></strong>.
            </>
          );
        } else {
          setCertificates(response.data);
          setError('');
        }
      })
      .catch(error => {
        console.error('Error fetching the certificates:', error);
        setError('Error al consultar los certificados. Por favor, intenta nuevamente.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">    
      <nav className="navbar">
        <img src="logo-mision.png" alt="Left" className="navbar-left" />
        <img src="logo-utp.png" alt="Right" className="navbar-right" />
      </nav><br></br>
      <div className="content">
        <div className="left-panel">
          <h2 className="title">Consulta tus certificados</h2>
          <p className="subtitle">Ingrese su número de documento.</p>
          <img src='personaje.png' alt="Ilustración de personaje" className="image" />
        </div>
        <div className="right-panel">
          <div className='inputs-container'>
            <form onSubmit={handleSubmit} className="form">
              <select
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                className="input same-width"
              >
                <option value="" disabled>Tipo de Documento</option>
                <option value="ti">T.I (Tarjeta de Identidad)</option>
                <option value="cc">C.C (Cédula de Ciudadanía)</option>
                <option value="ce">C.E (Cédula de Extranjería)</option>
                <option value="pep">PEP (Pasaporte)</option>
                <option value="nes">NES (Número de Estudiante)</option>
              </select>
              
              <input
                type="text"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
                placeholder="Documento identidad estudiante"
                className="input same-width"
              />

              <ReCAPTCHA
                sitekey="6LfB_CQqAAAAAGXyZqVmq3bXq40kW7FCvnKmkorb"
                onChange={handleRecaptchaChange}
              />
              <button 
                type="submit" 
                className="button"
              >Consultar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="instructions">
        <a
          href={`${process.env.PUBLIC_URL}/manual.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          className="instructions-link"
        >
          Clic acá para ver las instrucciones
        </a>
      </div>

      {loading && <p>Cargando...</p>}
      {error && <p className="error">{error}</p>}
      {certificates.length > 0 && (
        <div className="certificates-section">
          <h3 className="certificates-title">Tienes los siguientes certificados</h3>
          <div className="certificates">
            {certificates.map((certificate, index) => (
              <div key={index} className="certificate-card">
                <img src="2912780.png" alt={`Certificado ${index + 1}`} className="certificate-image" />
                <div>
                  <span className="certificate-name">Certificado {index + 1}</span>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      getDownloadUrl(certificate.name, (url) => {
                        window.open(url, '_blank');
                      });
                    }}
                    className="download-link"
                  >
                    Descargar
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/*{certificates.length === 0 && !loading && !error && alreadyRequested && (
        <div className="no-certificates">
          No hay certificados disponibles.
        </div>
      )}*/}
      <div className="footer">
        <p>¿Tienes problemas? <b><a class="link" target='_blank' href="https://drive.google.com/file/d/1teuUr91ADMHbnGmliinyuV-pD6va-RtJ/view?usp=sharing">Solicita tu certificado en nuestra mesa de ayuda - revisa el tutorial</a></b></p>
      </div>
    </div>
  );
};

export default App;